import { render, staticRenderFns } from "./InsElMenu.vue?vue&type=template&id=5803efd5&scoped=true&"
import script from "./InsElMenu.vue?vue&type=script&lang=ts&"
export * from "./InsElMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.8.3@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5803efd5",
  null
  
)

export default component.exports